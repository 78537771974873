.about-page {
  padding: 50px 0 0 0;
  background-image:  url(../../../public/assets/img/slide-five.png);
 min-height: 718px;
}

.about-page .page-title {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 25px;
  text-align: center;
}

.about-page .section-title {
  font-weight: 400;
  font-family: "Poppins",sans-serif;
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 20px;
}

.about-page p {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 20px;
}


.about-page img {
  width: 100%;
  margin-left: 0px;
  border-radius: 10px;
}

.about img{
   border-radius: 10px;
   margin-bottom: 10px;
}
.about h4{
  padding: 10px 0;
}


@media (min-width: 1200px) {
	.about img{
    height: 207px;
  }	
}
@media (min-width: 992px) {
  	.about img{
    height: 207px;
  }	
}