/* carousel */
.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: flex;
}


.carousel-control-prev-icon:before,
.carousel-control-next-icon:before {
  color: #fff;
}

.carousel-caption {
  position: absolute;
  top: 0;
}

.carousel-caption h4 {
  color: #fff;
}

.carousel img {
  display: block;
  height: 300px;
  width: 390px;
  padding: 30px;
  margin: 40px;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
}

.carousel a {
  margin: 0 auto;
}

@media (max-width: 900px) {
  .carousel-caption h2 {
    font-size: 2.5vw;
  }
}
